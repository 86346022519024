import React from "react";
import { BaseIconProps } from "./icon.types";

function PermissionUnlockIcon({ color = "white", className }: BaseIconProps) {
  const classes = className ? `${className}` : "";

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
    >
      <g clipPath="url(#clip0_4631_438241)">
        <g clipPath="url(#clip1_4631_438241)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.5026 5.33301C4.5026 3.40001 6.06961 1.83301 8.0026 1.83301C9.6328 1.83301 11.0038 2.94795 11.3923 4.45763C11.4612 4.72506 11.7338 4.88605 12.0012 4.81723C12.2687 4.74839 12.4296 4.47581 12.3608 4.20838C11.8613 2.26761 10.1001 0.833008 8.0026 0.833008C5.51732 0.833008 3.5026 2.84773 3.5026 5.33301V6.20152C3.35138 6.21215 3.20886 6.22599 3.07477 6.24402C2.4747 6.3247 1.96945 6.49729 1.56817 6.89854C1.16689 7.29987 0.994298 7.80507 0.913618 8.40514C0.835911 8.98314 0.835925 9.71801 0.835938 10.6297V10.7029C0.835925 11.6147 0.835911 12.3495 0.913618 12.9275C0.994298 13.5276 1.16689 14.0328 1.56817 14.4341C1.96945 14.8354 2.4747 15.008 3.07477 15.0887C3.65275 15.1663 4.38763 15.1663 5.29936 15.1663H10.7059C11.6176 15.1663 12.3525 15.1663 12.9305 15.0887C13.5305 15.008 14.0357 14.8354 14.4371 14.4341C14.8383 14.0328 15.0109 13.5276 15.0916 12.9275C15.1693 12.3495 15.1693 11.6147 15.1693 10.7029V10.6297C15.1693 9.71801 15.1693 8.98314 15.0916 8.40514C15.0109 7.80507 14.8383 7.29987 14.4371 6.89854C14.0357 6.49729 13.5305 6.3247 12.9305 6.24402C12.3525 6.16631 11.6176 6.16633 10.7059 6.16634H5.29936C5.01701 6.16633 4.75162 6.16633 4.5026 6.16863V5.33301ZM2.27528 7.60567C2.45978 7.42114 2.71884 7.30087 3.20802 7.23507C3.71158 7.16741 4.379 7.16634 5.33594 7.16634H10.6693C11.6262 7.16634 12.2936 7.16741 12.7972 7.23507C13.2864 7.30087 13.5454 7.42114 13.7299 7.60567C13.9145 7.79021 14.0347 8.04921 14.1005 8.53841C14.1682 9.04201 14.1693 9.70941 14.1693 10.6663C14.1693 11.6233 14.1682 12.2907 14.1005 12.7943C14.0347 13.2835 13.9145 13.5425 13.7299 13.727C13.5454 13.9115 13.2864 14.0318 12.7972 14.0976C12.2936 14.1653 11.6262 14.1663 10.6693 14.1663H5.33594C4.379 14.1663 3.71158 14.1653 3.20802 14.0976C2.71884 14.0318 2.45978 13.9115 2.27528 13.727C2.09077 13.5425 1.97047 13.2835 1.9047 12.7943C1.837 12.2907 1.83594 11.6233 1.83594 10.6663C1.83594 9.70941 1.837 9.04201 1.9047 8.53841C1.97047 8.04921 2.09077 7.79021 2.27528 7.60567Z"
            fill={color}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4631_438241">
          <rect width="16" height="16" fill={color} />{" "}
        </clipPath>
        <clipPath id="clip1_4631_438241">
          <rect width="16" height="16" fill={color} />{" "}
        </clipPath>
      </defs>
    </svg>
  );
}

export default PermissionUnlockIcon;
