import React, { useState, ReactNode } from "react";
import SuperToastContext from "./SuperToast.context";
import SuperToast from "./SuperToast.component";
import { SuperToastDto, ToastTypes } from "./SuperToast.types";

interface SuperToastProviderProps {
  children: ReactNode;
}

export const SuperToastProvider: React.FC<SuperToastProviderProps> = ({
  children
}) => {
  const [toasts, setToasts] = useState<Array<SuperToastDto>>([]);

  const addToast = (
    message: string,
    type = ToastTypes.DEFAULT,
    duration = 3000
  ) => {
    const id = Date.now();

    setToasts((prev) => [...prev, { id, message, type }]);
    setTimeout(() => removeToast(id), duration);
  };

  const removeToast = (id: number) => {
    setToasts((prev) => prev.filter((toast) => toast.id !== id));
  };

  return (
    <SuperToastContext.Provider
      value={{
        addToast,
        removeToast
      }}
    >
      {children}
      <SuperToast toasts={toasts} />
    </SuperToastContext.Provider>
  );
};
