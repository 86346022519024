import React from "react";
import { BaseIconProps } from "./icon.types";

function FolderIcon({ color = "white" }: BaseIconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4631_438255)">
        <path
          d="M2 5.46634C2 4.7196 2 4.34623 2.14533 4.06102C2.27315 3.81013 2.47713 3.60616 2.72801 3.47833C3.01323 3.33301 3.3866 3.33301 4.13333 3.33301H6.44968C6.7758 3.33301 6.93887 3.33301 7.09233 3.36985C7.22833 3.40251 7.3584 3.45638 7.47773 3.52949C7.61227 3.61194 7.7276 3.72724 7.9582 3.95785L8.0418 4.0415C8.2724 4.27211 8.38773 4.38741 8.52227 4.46986C8.6416 4.54297 8.77167 4.59684 8.90767 4.6295C9.06113 4.66634 9.2242 4.66634 9.55033 4.66634H11.8667C12.6134 4.66634 12.9868 4.66634 13.272 4.81167C13.5229 4.93949 13.7269 5.14347 13.8547 5.39435C14 5.67957 14 6.05294 14 6.79967V10.533C14 11.2797 14 11.6531 13.8547 11.9383C13.7269 12.1892 13.5229 12.3932 13.272 12.521C12.9868 12.6663 12.6134 12.6663 11.8667 12.6663H4.13333C3.38659 12.6663 3.01323 12.6663 2.72801 12.521C2.47713 12.3932 2.27315 12.1892 2.14533 11.9383C2 11.6531 2 11.2797 2 10.533V5.46634Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4631_438255">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default FolderIcon;
