import {
  AUTHENTIC_API_URL,
  AUTHENTIC_CLIENT_ID,
  SERVICES_API_URL
} from "@/config/constants";
import { isServicesError } from "@/lib/services";
import { getAccessToken } from "@/lib/network";
import { ServicesErrorResponse } from "@/types/network";
import {
  ForgotPasswordPayload,
  InvitationPayload,
  InvitationResponse,
  ResetPasswordPayload,
  SignInPayload,
  SignInResponse,
  SignUpPayload,
  SignUpResponse,
  UserDetailsPayload,
  UserDetailsResponse,
  VerifySignupPayload,
  VerifySignupResponse
} from "@/types/services/auth-service";

/**
 * Sends a sign-in request to the authentic API.
 *
 * @param {SignInPayload} payload - The payload containing the user's email and password.
 * @returns {Promise<SignInResponse | ServicesErrorResponse>}
 * Resolves to a `SignInResponse` on success or an `ServicesErrorResponse` on error.
 *
 * @example
 * const payload = { email: "user@example.com", password: "securepassword" };
 * const response = await signIn(payload);
 *
 * if (isServicesError(response)) {
 *   console.error("Sign-in failed:", response.message);
 * } else {
 *   console.log("Sign-in successful:", response.accessToken);
 * }
 */
export const authenticSignIn = async (
  payload: SignInPayload
): Promise<SignInResponse | ServicesErrorResponse> => {
  const response = await fetch(`${AUTHENTIC_API_URL}/auth/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Client-ID": AUTHENTIC_CLIENT_ID
    },
    body: JSON.stringify(payload)
  });

  const json = await response.json();

  if (isServicesError(json)) {
    return json;
  }

  return json as SignInResponse;
};

export const getAuthenticUserDetails = async ({
  userId
}: UserDetailsPayload): Promise<
  UserDetailsResponse | ServicesErrorResponse
> => {
  const token = getAccessToken();

  const response = await fetch(`${AUTHENTIC_API_URL}/auth/users/${userId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-Client-ID": AUTHENTIC_CLIENT_ID,
      Authorization: `Bearer ${token}`
    }
  });

  const json = await response.json();

  if (isServicesError(json)) {
    return json;
  }

  return json as UserDetailsResponse;
};

export const forgotPassword = async (
  payload: ForgotPasswordPayload
): Promise<void> => {
  await fetch(`${AUTHENTIC_API_URL}/auth/password/forgot`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Client-ID": AUTHENTIC_CLIENT_ID
    },
    body: JSON.stringify(payload)
  });
};

/**
 * Sends a reset password request to the Authentic API.
 *
 * @param {ResetPasswordPayload} payload - The payload containing the reset ID and the new password.
 * @param {string} payload.id - The unique identifier for the password reset request.
 * @param {string} payload.password - The new password to be set for the user.
 * @returns {Promise<void | ServicesErrorResponse>}
 * Resolves to `void` on success or an `ServicesErrorResponse` on error.
 *
 * @example
 * const payload = { id: "reset-token", password: "NewSecurePassword123!" };
 * const response = await resetPassword(payload);
 *
 * if (isServicesError(response)) {
 *   console.error("Password reset failed:", response.message);
 * } else {
 *   console.log("Password reset successful");
 * }
 */
export const resetPassword = async ({
  id,
  password
}: ResetPasswordPayload): Promise<void | ServicesErrorResponse> => {
  const response = await fetch(
    `${AUTHENTIC_API_URL}/auth/password/submit?id=${id}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Client-ID": AUTHENTIC_CLIENT_ID
      },
      body: JSON.stringify({ password })
    }
  );

  if (!response.ok) {
    const json = await response.json();

    if (isServicesError(json)) {
      return json;
    }
  }
};

export const signUp = async (
  payload: SignUpPayload
): Promise<SignUpResponse | ServicesErrorResponse> => {
  const response = await fetch(`${AUTHENTIC_API_URL}/auth/signup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Client-ID": AUTHENTIC_CLIENT_ID
    },
    body: JSON.stringify(payload)
  });

  const json = await response.json();

  if (isServicesError(json)) {
    return json;
  }

  return json as SignUpResponse;
};

export const verifySignUp = async (
  payload: VerifySignupPayload
): Promise<VerifySignupResponse | ServicesErrorResponse> => {
  const { id, confirmationCode } = payload;

  const response = await fetch(`${AUTHENTIC_API_URL}/auth/verify?id=${id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Client-ID": AUTHENTIC_CLIENT_ID
    },
    body: JSON.stringify({
      code: confirmationCode
    })
  });

  const json = await response.json();

  if (isServicesError(json)) {
    return json;
  }

  return json as VerifySignupResponse;
};

export const getInvitationDetails = async (
  payload: InvitationPayload
): Promise<InvitationResponse | ServicesErrorResponse> => {
  const { token } = payload;

  const response = await fetch(
    `${SERVICES_API_URL}/invitations/v0/invitations/${token}/status`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    }
  );

  const json = await response.json();

  if (isServicesError(json)) {
    return json;
  }

  return json as InvitationResponse;
};
