import React from "react";
import { BaseIconProps } from "./icon.types";

function AccessRequestIcon({ color = "white", className }: BaseIconProps) {
  const classes = className ? `${className}` : "";

  return (
    <svg
      className={classes}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4631_438269)">
        <path
          d="M9.33138 2.33301V7.33301V2.99967C9.33138 2.44739 9.77911 1.99967 10.3314 1.99967C10.8836 1.99967 11.3314 2.44739 11.3314 2.99967V7.33301V4.99967C11.3314 4.44739 11.7791 3.99967 12.3314 3.99967C12.8836 3.99967 13.3314 4.44739 13.3314 4.99967V10.6663C13.3314 12.8755 11.5405 14.6663 9.33138 14.6663H8.57985C7.56358 14.6663 6.58546 14.2795 5.84407 13.5845L2.53835 10.4853C2.06048 10.0373 2.04831 9.28274 2.51149 8.81954C2.96431 8.36674 3.69847 8.36674 4.15128 8.81954L5.33139 9.99967V4.33301C5.33139 3.78072 5.7791 3.33301 6.33139 3.33301C6.88365 3.33301 7.33138 3.78072 7.33138 4.33301V7.33301V2.33301C7.33138 1.78072 7.77911 1.33301 8.33138 1.33301C8.88365 1.33301 9.33138 1.78072 9.33138 2.33301Z"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4631_438269">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AccessRequestIcon;
