import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import styles from "./SuperToast.module.scss";
import { SuperToastDto, SuperToastProps, ToastTypes } from "./SuperToast.types";
import CloseIcon from "@/components/icons/CloseIcon";
import { useSuperToast } from "./SuperToast.hook";
import { toastVariants } from "./data/motion";
import { iconConfig } from "./data/icon";

const SuperToast = ({ toasts }: SuperToastProps) => {
  const { removeToast } = useSuperToast();

  const iconTemplate = (type: ToastTypes) => {
    const iconEntry = iconConfig[type];

    if (!iconEntry) return null;

    const { component: IconComponent, color } = iconEntry;

    return (
      <span className={styles.iconWrapper}>
        {React.createElement(IconComponent, { color })}
        <span className={styles.single}>|</span>
      </span>
    );
  };

  return (
    <div className={styles.toastContainer}>
      <AnimatePresence>
        {toasts.map(({ id, type, message }: SuperToastDto) => (
          <motion.div
            key={id}
            className={styles.superToast}
            variants={toastVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.3 }}
          >
            <div className={styles.iconAndText}>
              {iconTemplate(type)}
              <span>{message}</span>
            </div>
            <CloseIcon
              className={styles.icon}
              color="var(--dark-1100)"
              onClick={() => removeToast(id)}
            />
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};

export default SuperToast;
