import { BaseIconProps } from "@/components/icons/icon.types";

export interface SuperToastProps {
  toasts: Array<SuperToastDto>;
}

export interface SuperToastDto {
  id: number;
  message: string;
  type: ToastTypes;
}

export interface SuperToastContextType {
  addToast: (message: string, type?: ToastTypes, duration?: number) => void;
  removeToast: (id: number) => void;
}

export enum ToastTypes {
  SUCCESS = "SUCCESS",
  FAIL = "FAIL",
  PERMISSION_LOCK = "PERMISSION_LOCK",
  PERMISSION_UNLOCK = "PERMISSION_UNLOCK",
  FOLDER = "FOLDER",
  ACCESS_REQUEST = "ACCESS_REQUEST",
  DEFAULT = "DEFAULT"
}

interface IconConfigEntry {
  component: React.ComponentType<BaseIconProps>;
  color: string;
}

export type IconConfig = {
  [key in ToastTypes]: IconConfigEntry;
};
