import { ServicesErrorResponse } from "@/types/network";

/**
 * Checks if the payload is considered an error response from Authentic.
 *
 * @param {object} payload - The payload to check.
 * @returns {boolean} `true` if the payload contains the fields `code`, `errorDescription`, `errorType`, and `message`; otherwise, `false`.
 */
export const isServicesError = (payload): payload is ServicesErrorResponse => {
  return (
    payload !== null &&
    typeof payload === "object" &&
    "code" in payload &&
    "errorDescription" in payload &&
    "errorType" in payload &&
    "message" in payload
  );
};
