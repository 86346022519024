import { API_URL } from "@/config/constants";
import { getAccessToken } from "@/lib/network";
import { UserInfoResponse } from "@/types/services/users-service";

export const getUserInfo = async (): Promise<UserInfoResponse> => {
  const token = getAccessToken();

  const response = await fetch(`${API_URL}/api/v1/users/me`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  });

  return await response.json();
};
