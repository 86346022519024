import React from "react";
import { BaseIconProps } from "./icon.types";

function FailIcon({ color = "white", className }: BaseIconProps) {
  const classes = className ? `${className}` : "";

  return (
    <svg
      className={classes}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4631_438180)">
        <path
          d="M7.50008 1C3.91592 1 1 3.91608 1 7.50047C1.00036 11.0845 3.91628 14.0003 7.50008 14.0003C11.0842 14.0003 14 11.0845 14 7.50039C14 3.91608 11.0842 1 7.50008 1ZM7.50008 2.18426C8.75279 2.18426 9.90543 2.62014 10.815 3.34787L3.34775 10.8151C2.62018 9.90555 2.18438 8.75299 2.18426 7.50043C2.18426 4.56908 4.56892 2.18426 7.50008 2.18426ZM7.50008 12.8161C6.24741 12.8161 5.09469 12.3802 4.18506 11.6524L11.6524 4.1853C12.38 5.09493 12.8158 6.24765 12.8158 7.50039C12.8157 10.4315 10.4312 12.8161 7.50008 12.8161Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_4631_438180">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default FailIcon;
