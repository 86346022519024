import React from "react";
import { BaseIconProps } from "./icon.types";

function CloseIcon({ color = "white", className }: BaseIconProps) {
  const classes = className ? `${className}` : "";

  return (
    <svg
      className={classes}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 8.33301L3.71716 11.0501C3.87337 11.2064 4.12663 11.2064 4.28284 11.0501L6 9.33301"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10.6667 4.66699L8 7.33366"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.66406 8.00033L7.71453 11.0508C7.8708 11.2071 8.124 11.2071 8.28026 11.0508L14.6641 4.66699"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default CloseIcon;
