import React, { useEffect, useRef } from "react";
import styles from "./BackgroundVideo.module.scss";

function BackgroundVideo() {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.setAttribute("webkit-playsinline", "");
      videoRef.current.setAttribute("playsinline", "");
    }
  }, []);

  return (
    <video className={styles.backgroundVideo} autoPlay loop muted playsInline>
      <source src="/videos/clouds.mp4" type="video/mp4" />
    </video>
  );
}

export default BackgroundVideo;
