import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "./auth.context";
import { getUserInfo } from "@/services/users-service";
import {
  authenticSignIn,
  getAuthenticUserDetails
} from "@/services/auth-service";
import { SignInPayload } from "@/types/services/auth-service";
import {
  getAccessToken,
  removeAccessToken,
  storeAccessToken
} from "@/lib/network";
import { isServicesError } from "@/lib/services";
import { AuthContextType, AuthProviderProps } from "./auth.types";

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const retrieveUserInfo = useCallback(async () => {
    const token = getAccessToken();

    if (token) {
      try {
        const { data } = await getUserInfo();
        setUser(data);
      } catch (error) {
        console.error("Failed to retrieve user info:", error);
        removeAccessToken();
        navigate("/");
      }
    }

    setLoading(false);
  }, [navigate]);

  useEffect(() => {
    retrieveUserInfo();
  }, [retrieveUserInfo]);

  const signIn = async (data: SignInPayload): Promise<void> => {
    const { email, password } = data;

    const payload: SignInPayload = {
      email,
      password
    };

    const signInRes = await authenticSignIn(payload);

    if (isServicesError(signInRes)) {
      throw new Error(signInRes.message);
    }

    const { accessToken, userId } = signInRes;

    storeAccessToken(accessToken);

    const userDetailsRes = await getAuthenticUserDetails({
      userId
    });

    if (isServicesError(userDetailsRes)) {
      removeAccessToken();
      throw new Error(userDetailsRes.message);
    }

    const { data: info } = await getUserInfo();
    setUser(info);
  };

  const signOut = () => {
    removeAccessToken();
    setUser(null);
    navigate("/");
  };

  const value: AuthContextType = {
    user,
    signIn,
    signOut
  };

  // Shows nothing while loading -- bg only
  if (loading) return null;

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
