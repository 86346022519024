import React, { useEffect, useState } from "react";
import styles from "./root.module.scss";
import { SCREEN_TYPES } from "./root.types";
import { useAuth } from "@/contexts/auth/auth.hook";
import { applyBackgroundBlur, removeBackgroundBlur } from "@/lib/theme";
import Logo from "@/components/screens/Logo/Logo.component";
import Login from "@/components/screens/Login/Login.component";

function RootPage() {
  const [currentScreen, setCurrentScreen] = useState<SCREEN_TYPES>(
    SCREEN_TYPES.LOGO
  );

  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      return setCurrentScreen(SCREEN_TYPES.DASHBOARD);
    }

    setCurrentScreen(SCREEN_TYPES.LOGO);
  }, [user]);

  const goToLogoScreen = () => {
    setCurrentScreen(SCREEN_TYPES.LOGO);

    removeBackgroundBlur();
  };

  const goToLoginScreen = () => {
    setCurrentScreen(SCREEN_TYPES.LOGIN);

    applyBackgroundBlur();
  };

  const goToDashboardScreen = () => {
    setCurrentScreen(SCREEN_TYPES.DASHBOARD);
  };

  const renderContent = () => {
    if (currentScreen === SCREEN_TYPES.LOGIN) {
      return (
        <Login
          goToLogoScreen={goToLogoScreen}
          goToDashboardScreen={goToDashboardScreen}
        />
      );
    }

    if (currentScreen === SCREEN_TYPES.DASHBOARD) {
      return <h1>Dashboard</h1>;
    }

    return <Logo onClick={goToLoginScreen} />;
  };

  return <div className={styles.root}>{renderContent()}</div>;
}

export default RootPage;
