import React from "react";
import { BaseIconProps } from "./icon.types";

function CloseIcon({ color = "white", className, onClick }: BaseIconProps) {
  const classes = className ? `${className}` : "";

  return (
    <svg
      className={classes}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick ?? undefined}
    >
      <path
        d="M11.3307 4.66699L4.66406 11.3336"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.66406 4.66699L11.3307 11.3336"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default CloseIcon;
