import FailIcon from "@/components/icons/FailIcon";
import PermissionLock from "@/components/icons/PermissionLockIcon";
import PermissionUnlock from "@/components/icons/PermissionUnlockIcon";
import SuccessIcon from "@/components/icons/SuccessIcon";
import { IconConfig, ToastTypes } from "../SuperToast.types";
import FolderIcon from "@/components/icons/FolderIcon";
import AccessRequestIcon from "@/components/icons/AccessRequestIcon";

export const iconConfig: IconConfig = {
  [ToastTypes.SUCCESS]: { component: SuccessIcon, color: "var(--brand-200)" },
  [ToastTypes.FAIL]: { component: FailIcon, color: "var(--danger-100)" },
  [ToastTypes.PERMISSION_LOCK]: {
    component: PermissionLock,
    color: "var(--dark-300)"
  },
  [ToastTypes.PERMISSION_UNLOCK]: {
    component: PermissionUnlock,
    color: "var(--green-100)"
  },
  [ToastTypes.FOLDER]: {
    component: FolderIcon,
    color: "var(--light-200)"
  },
  [ToastTypes.ACCESS_REQUEST]: {
    component: AccessRequestIcon,
    color: "var(--light-300)"
  },
  [ToastTypes.DEFAULT]: null
};
